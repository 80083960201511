/* Variáveis*/
@font-family: "Open Sans", sans-serif;
@font-family-titles: "Montserrat", sans-serif;
@color: #666;
@color-links: #2881F7;
@color-links-hover: #094CA5;

/* Definições gerais */
body {
	// background-image: url('../images/page.png');
	// background-repeat: repeat;
	background: #F2F2F2;
	font-family: @font-family;
	color: @color;
}
a {
	outline: none;
	text-decoration: none;
	color: @color-links;
	&:hover, &:active, &:focus {
		outline: none;
		text-decoration: none;
		color: @color-links-hover;
	}
}
p {
	padding: 0px;
	margin: 0px 0px 10px 0px;
}
h1, h2, h3, h4, h5, h6 {
	font-family: @font-family-titles;
	font-weight: 400;
	margin: 0px 0px 15px 0px;
	padding: 0px;
}
.back-to-top {
	position: fixed;
	display: none;
	background: @color-links;
	color: #FFF;
	width: 44px;
	height: 44px;
	text-align: center;
	line-height: 1;
	font-size: 16px;
	border-radius: 50%;
	right: 15px;
	bottom: 15px;
	transition: background 0.5s;
	z-index: 11;
}
.back-to-top i {
	padding-top: 12px;
	color: #FFF;
}
.p-recursos {
  padding-left: 15px;
  margin: 0 0 10px 0;
  color: @color-links-hover;
}
.style-none {
  display: none;
}
.scroll-down {
	width: 30px;
	height: auto;
}
/* Fim definições gerais */

/* Preloader */
#preloader {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 9999;
	overflow: hidden;
	background: #FFF;
	&:before {
		content: "";
		position: fixed;
		top: ~"calc(50% - 30px)";
		left: ~"calc(50% - 30px)";
		border: 6px solid #F2F2F2;
		border-top: 6px solid @color-links-hover;
		border-radius: 50%;
		width: 60px;
		height: 60px;
		-webkit-animation: animate-preloader 1s linear infinite;
		animation: animate-preloader 1s linear infinite;
	}
}
@-webkit-keyframes animate-preloader {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes animate-preloader {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
/* Fim preloader */

/* Header */
#header {
	padding: 30px 0px;
	height: 92px;
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	transition: all 0.5s;
	z-index: 997;
	#logo {
  		float: left;
  		h1 {
			font-size: 34px;
			margin: 0px;
			padding: 0px;
			line-height: 1;
			font-family: @font-family-titles;
			font-weight: 700;
			letter-spacing: 3px;
			a {
				color: #FFF;
				padding-left: 10px;
				border-left: 4px solid @color-links;
				&:hover {
					border-left: 4px solid @color-links-hover;
				}
			}
  		}
  		img {
			padding: 0px;
			margin: 0px;
  		}
	}
}
#header.header-scrolled {
	background: rgba(204, 204, 204, 0.9);
	padding: 20px 0px;
	height: 72px;
	transition: all 0.5s;
}
/* Fim header */

/* Navigation Menu */
.nav-menu {
	* {
		margin: 0px;
		padding: 0px;
		list-style: none;
	}
	ul {
		position: absolute;
		display: none;
		top: 100%;
		left: 0px;
		z-index: 99;
		margin: 4px 0px 0px 0px;
		padding: 10px;
		box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
		background: #FFF;
		ul {
			top: 0px;
  			left: 100%;
  			margin: 0px;
		}
		li {
			min-width: 180px;
			transition: 0.3s;
			a {
				padding: 10px;
				color: #333;
				transition: 0.3s;
				display: block;
				font-size: 13px;
				text-transform: none;
			}
			&:hover {
				> a {
					color: @color-links;
				}
			}
		}
	}
	li {
		position: relative;
		white-space: nowrap;
		&:hover {
			> ul {
				display: block;
			}
			> a {
				color: @color-links-hover;
			}
		}
		.sfHover {
			> ul {
				display: block;
			}
		}
	}
	> li {
		float: left;
		margin-left: 10px;
	}
	a {
		padding: 0px 8px 10px 8px;
		text-decoration: none;
		display: inline-block;
		/* color: #FFF; */
		font-family: @font-family-titles;
		font-weight: 700;
		font-size: 13px;
		text-transform: uppercase;
		outline: none;
	}
	> .menu-active {
		> a {
			color: @color-links;
		}
	}
}
.sf-arrows {
	.sf-with-ul {
  		padding-right: 30px;
  		&:after {
			content: "\f107";
			position: absolute;
			right: 15px;
			font-family: FontAwesome;
			font-style: normal;
			font-weight: normal;
  		}
	}
	ul {
		.sf-with-ul:after {
			content: "\f105";
		}
	}
}
#nav-menu-container {
	float: right;
	margin: 0px;
}
#mobile-nav-toggle {
	position: fixed;
	right: 0px;
	top: 0px;
	z-index: 999;
	margin: 20px 20px 0px 0px;
	border: 0px;
	background: none;
	font-size: 24px;
	display: none;
	transition: all 0.4s;
	outline: none;
	cursor: pointer;
	i {
		color: #FFF;
	}
}
#mobile-nav {
	position: fixed;
	top: 0px;
	padding-top: 18px;
	bottom: 0px;
	z-index: 998;
	background: rgba(0, 0, 0, 0.8);
	left: -260px;
	width: 260px;
	overflow-y: auto;
	transition: 0.4s;
	ul {
		padding: 0px;
		margin: 0px;
		list-style: none;
		li {
			position: relative;
			a {
				color: #FFF;
				font-size: 13px;
				text-transform: uppercase;
				overflow: hidden;
				padding: 10px 22px 10px 15px;
				position: relative;
				text-decoration: none;
				width: 100%;
				display: block;
				outline: none;
				font-weight: 700;
				font-family: @font-family-titles;
				&:hover {
					color: @color-links;
				}
			}
			li {
				padding-left: 30px;
				.menu-active {
					a {
						color: @color-links;
					}
				}
			}
		}
		.menu-has-children {
			i {
				position: absolute;
				right: 0px;
				z-index: 99;
				padding: 15px;
				cursor: pointer;
				color: #FFF;
				.fa-chevron-up {
					color: @color-links;
				}
			}
			li {
				a {
					text-transform: none;
				}
			}
		}
		.menu-item-active {
			color: @color-links;
		}
	}
}
#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  top: 0px;
  left: 0px;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  display: none;
}
body.mobile-nav-active {
  overflow: hidden;
}
body.mobile-nav-active #mobile-nav {
  left: 0px;
}
body.mobile-nav-active #mobile-nav-toggle {
  color: #FFF;
}
/* Fim Navigation Menu */

/* Introdução */
#intro {
	display: table;
	width: 100%;
	height: 100vh;
	background: #000;
	.carousel-item {
		width: 100%;
		height: 100vh;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		&:before {
			content: '';
			background-color: rgba(0, 0, 0, 0.7);
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0px;
			right: 0px;
			left: 0px;
			bottom: 0px;
		}
	}
	.carousel-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		position: absolute;
		bottom: 0px;
		top: 0px;
		left: 0px;
		right: 0px;
	}
	.carousel-background {
		img {
			max-width: 100%;
		}
	}
	.carousel-content {
		text-align: center;
	}
	h2 {
		color: #FFF;
		margin-bottom: 30px;
		font-size: 48px;
		font-weight: 700;
	}
	p {
		width: 80%;
		margin: 0 auto 30px auto;
		color: #FFF;
	}
	.carousel-fade {
		overflow: hidden;
		.carousel-inner {
			.carousel-item {
				transition-property: opacity;
			}
			.active.carousel-item-left, .active.carousel-item-right {
				opacity: 0;
			}
			.active, .carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
				opacity: 1;
				transition: 0.5s;
			}
			.carousel-item-next, .carousel-item-prev, .active.carousel-item-left, .active.carousel-item-right {
				left: 0px;
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
		}
	}
	.carousel-control-prev, .carousel-control-next {
		width: 10%;
	}
	.carousel-control-next-icon, .carousel-control-prev-icon {
		background: none;
		font-size: 32px;
		line-height: 1;
	}
	.carousel-indicators li {
		cursor: pointer;
	}
	.btn-get-started {
		font-family: @font-family-titles;
		font-weight: 500;
		font-size: 16px;
		letter-spacing: 1px;
		display: inline-block;
		padding: 8px 32px;
		border-radius: 50px;
		transition: 0.5s;
		margin: 10px;
		color: #FFF;
		background: @color-links-hover;
		border: 1px solid @color-links-hover;
		&:hover {
			background: #FFF;
			color: @color-links-hover;
			border: 1px solid @color-links-hover;
		}
	}
}
/* Fim Introdução */

/* Serviço */
#featured-services {
	background-image: linear-gradient(0deg, #000000 0%, #242323 50%, #000000 100%);
	.box {
		padding: 30px 20px;
	}
	.box-bg {
		background-image: linear-gradient(0deg, #000000 0%, #242323 50%, #000000 100%);
	}
	i {
		color: @color-links;
		font-size: 48px;
		display: inline-block;
		line-height: 1;
	}
	h4 {
		font-weight: 400;
		margin: 15px 0;
		font-size: 18px;
		a {
			color: #FFF;
			&:hover {
				color: @color-links;
			}
		}
	}
	p {
		font-size: 14px;
		line-height: 24px;
		color: #FFF;
		margin-bottom: 0px;
	}
}
#services {
	background: url("../images/bg/bg-3.jpg") center top no-repeat fixed;
	background-size: cover;
	padding: 60px 0px 40px 0px;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		left: 0px;
		right: 0px;
		top: 0px;
		bottom: 0px;
		background: rgba(201, 202, 205, 0.88);
		z-index: 9;
	}
	.container {
		position: relative;
		z-index: 10;
	}
	.box {
  		margin-bottom: 30px;
		&:hover .title a {
			color: @color-links;
		}
	}
	.icon {
		float: left;
		i {
			color: @color-links;
			font-size: 36px;
			line-height: 1;
			transition: 0.5s;
		}
	}
	.title {
		margin-left: 60px;
		font-weight: 700;
		margin-bottom: 15px;
		font-size: 18px;
		a {
			color: #111;
		}
	}
	.description {
		font-size: 14px;
		margin-left: 60px;
		line-height: 24px;
		margin-bottom: 0px;
		text-align: justify;
	}
	.btn-get-started {
		#intro .btn-get-started;
	}
}
/* Fim Serviço */

/* Sections */
.section-header {
	h3 {
		font-size: 32px;
		color: #111;
		text-transform: uppercase;
		text-align: center;
		font-weight: 700;
		position: relative;
		padding-bottom: 15px;
		&:before {
			content: '';
			position: absolute;
			display: block;
			width: 120px;
			height: 1px;
			background: #A8A9AB;
			bottom: 1px;
			left: ~"calc(50% - 60px)";
		}
		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 40px;
			height: 3px;
			background: @color-links-hover;
			bottom: 0px;
			left: ~"calc(50% - 20px)";
		}
	}
	p {
		text-align: center;
		padding-bottom: 30px;
		color: #333;
	}
}
.section-bg {
  background: #F7F7F7;
}
/* Fim Sections */

/* Sobre Nós*/
#about {
	background: url("../images/bg/bg-5.jpg") center top no-repeat fixed;
	background-size: cover;
	padding: 60px 0px 40px 0px;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		left: 0px;
		right: 0px;
		top: 0px;
		bottom: 0px;
		background: rgba(152, 154, 158, 0.78);
		z-index: 9;
	}
	.container {
		position: relative;
		z-index: 10;
	}
	.about-col {
		background: #FFF;
		border-radius: 0px 0px 4px 4px;
		box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
		margin-bottom: 20px;
		height: 480px;
		.img {
			position: relative;
			img {
				border-radius: 4px 4px 0px 0px;
			}
		}
		.icon {
			width: 64px;
			height: 64px;
			padding-top: 8px;
			text-align: center;
			position: absolute;
			background-color: @color-links;
			border-radius: 50%;
			text-align: center;
			border: 1px solid @color-links;
			left: ~"calc( 50% - 32px)";
			bottom: -30px;
			transition: 0.3s;
		}
		i {
			font-size: 45px;
			line-height: 1;
			color: #FFF;
			transition: 0.3s;
		}
		&:hover {
			.icon {
				background-color: #FFF;
			}
			i {
  				color: @color-links;
			}
		}
		h2 {
			color: #000;
			text-align: center;
			font-weight: 700;
			font-size: 20px;
			padding: 0px;
			margin: 40px 0px 12px 0px;
			span {
				color: #000;
				&:hover {
					color: @color-links;
				}
			}
		}
		p {
			font-size: 14px;
			line-height: 24px;
			color: #333;
			margin-bottom: 0px;
			padding: 0px 20px 20px 20px;
		}
	}
}
/* Fim Sobre Nós*/

/* Atuação */
#atuacao {
	padding: 60px 0px;
	background-color: rgba(238, 239, 241, 0.92);
	#atuacao-flters {
		padding: 0px;
		margin: 5px 0 35px 0px;
		list-style: none;
		text-align: center;
		li {
			cursor: pointer;
			margin: 15px 15px 15px 0px;
			display: inline-block;
			padding: 10px 20px;
			font-size: 12px;
			line-height: 20px;
			color: #666666;
			border-radius: 4px;
			text-transform: uppercase;
			background: #FFF;
			margin-bottom: 5px;
			transition: all 0.3s ease-in-out;
			&:last-child {
				margin-right: 0px;
			}
			&:hover {
				background: @color-links-hover;
				color: #FFF;
			}
			&.filter-active {
				background: @color-links-hover;
				color: #FFF;
			}
		}
	}
}
/* Fim Atuação */

/* Clientes */
#clients {
	padding: 60px 0px;
	background: #F7F7F7;
	img {
		max-width: 100%;
		opacity: 0.5;
		transition: 0.3s;
		padding: 15px 0px;
		&:hover {
			opacity: 1;
		}
	}
	.owl-nav, .owl-dots {
		margin-top: 5px;
		text-align: center;
	}
	.owl-dot {
		display: inline-block;
		margin: 0px 5px;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background-color: #DDD;
		&.active {
			background-color: @color-links;
		}
	}
}
/* Fim Clientes */

/* Contato */
#contact {
	padding: 60px 0px;
	.contact-info {
		text-align: center;
		i {
			font-size: 48px;
			display: inline-block;
			margin-bottom: 10px;
			color: @color-links;
		}
		address,  p {
			margin-bottom: 0px;
			color: #000;
		}
		h3 {
			font-size: 18px;
			margin-bottom: 15px;
			font-weight: bold;
			text-transform: uppercase;
			color: #999;
		}
		a {
			color: #000;
			&:hover {
				color: @color-links;
			}
		}
	}
	.contact-address,
	.contact-phone,
	.contact-wphone,
	.contact-email,
	.contact-facebook,
	.contact-instagram {
		margin-bottom: 20px;
	}
}
#contact .form #sendmessage {
	color: #27A735;
	border: 1px solid #27a735;
	display: none;
	text-align: center;
	padding: 15px;
	font-weight: 600;
	margin-bottom: 15px;
	background-color: #C7ECC7;
	border-radius: 10px;
}
#contact .form #errormessage {
	color: red;
	display: none;
	border: 1px solid red;
	padding: 15px;
	font-weight: 600;
	margin-bottom: 15px;
	border-radius: 10px;
	background-color: #E9BABA;
}
#contact .form #sendmessage.show, #contact .form #errormessage.show, #contact .form .show {
	display: block;
}
#contact .form .validation {
	color: red;
	display: none;
	margin: 0 0 20px;
	font-weight: 400;
	font-size: 13px;
}
#contact .form input, #contact .form textarea {
	padding: 10px 14px;
	border-radius: 0;
	box-shadow: none;
	font-size: 15px;
}
#contact .form button[type="submit"] {
	background: @color-links;
	border: 0;
	padding: 10px 30px;
	color: #FFF;
	transition: 0.4s;
	cursor: pointer;
	border-radius: 8px;
	&:hover, &:focus {
		background: @color-links-hover;
	}
	&:disabled {
		cursor: not-allowed;
		background: #CCC;
		&:hover {
			background: #7A7A7A;
		}
	}
}
/* Fim Contato */

/* Download */
#download {
	background: url("../images/bg/bg-4.jpg") center no-repeat fixed;
	background-size: cover;
	padding: 60px 0 40px 0;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		left: 0px;
		right: 0px;
		top: 0px;
		bottom: 0px;
		background: rgba(56, 90, 128, 0.92);
		z-index: 9;
	}
	.container {
		position: relative;
		z-index: 10;
	}
	#download-flters {
		padding: 0px;
		margin: 5px 0px 35px 0px;
		list-style: none;
		text-align: center;
		li {
			cursor: pointer;
			margin: 15px 15px 15px 0;
			display: inline-block;
			padding: 10px 20px;
			font-size: 12px;
			line-height: 20px;
			color: #666666;
			border-radius: 4px;
			text-transform: uppercase;
			background: #FFF;
			margin-bottom: 5px;
			transition: all 0.3s ease-in-out;
			&:hover, &.filter-active {
				background: @color-links;
				color: #FFF;
			}
			&:last-child {
				margin-right: 0px;
			}
		}
	}
	.download-wrap {
		box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
		transition: 0.3s;
		&:hover {
			box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
		}
	}
	.download-item {
		position: relative;
		overflow: hidden;
		background: url("../images/downloadarrow.png") center;
		margin-bottom: 15px;
		&::before {
			content: '';
			position: absolute;
			left: 15px;
			right: 15px;
			top: 0px;
			bottom: 0px;
			// background: rgb(79, 131, 181);
			background: rgb(7, 20, 32);
			z-index: 9;
			border-radius: 8px;
			opacity: 0.2;
		}
		.download-info {
			text-align: center;
			padding: 20px 0px 10px 0px;
			height: 90px;
			border-radius: 8px;
			h4 {
				font-size: 16px;
				line-height: 14px;
				font-weight: 700;
				margin-bottom: 5px;
				padding-bottom: 0px;
				a {
					color: #FFF;
					&:hover {
						color: #A4A4A4;
					}
				}
			}
			p {
				padding: 0px;
				margin: 0px;
				color: #C9DAED;
				font-weight: 500;
				font-size: 14px;
				text-transform: uppercase;
			}
		}
	}
	.download-info {
		position: relative;
		z-index: 10;
	}
}
/* Fim Download */

/* Footer */
#footer {
	background: #808080;
	padding: 0 0 30px 0;
	color: #EEE;
	font-size: 14px;
	.footer-top {
		background: #989898;
		padding: 60px 0 30px 0;
		.footer-info {
			margin-bottom: 30px;
			h3.ico {
				font-size: 34px;
				margin: 0 0 20px 0;
				padding: 2px 0 2px 10px;
				line-height: 1;
				font-family: @font-family-titles;
				font-weight: 700;
				letter-spacing: 3px;
				border-left: 4px solid @color-links-hover;
			}
			h3.efi {
				font-size: 34px;
				margin: 0 0 20px 0;
				padding: 2px 0 2px 10px;
				line-height: 1;
				font-family: @font-family-titles;
				font-weight: 700;
				letter-spacing: 3px;
				border-left: 4px solid #276891;
			}
		}
		p {
			font-size: 14px;
			line-height: 24px;
			margin-bottom: 0;
			font-family: @font-family-titles;
			color: #EEE;
		}
		.social-links a {
			font-size: 18px;
			display: inline-block;
			background: #333;
			color: #EEE;
			line-height: 1;
			padding: 8px 0;
			margin-right: 4px;
			border-radius: 50%;
			text-align: center;
			width: 36px;
			height: 36px;
			transition: 0.3s;
			&:hover {
				background: @color-links;
				color: #FFF;
			}
		}
		h4 {
			font-size: 14px;
			font-weight: bold;
			color: #FFF;
			text-transform: uppercase;
			position: relative;
			padding-bottom: 12px;
			&::before {
				right: 0;
				background: #555;
			}
			&::after {
				background: @color-links;
				width: 60px;
			}
		}
		&::before, &::after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			height: 2px;
		}
		.footer-links {
			margin-bottom: 30px;
			ul {
				list-style: none;
				padding: 0;
				margin: 0;
				i {
					padding-right: 8px;
					color: #DDD;
				}
				li {
					border-bottom: 1px solid #333;
					padding: 10px 0;
					&:first-child {
						padding-top: 0;
					}
				}
				a {
					color: #EEE;
					&:hover {
						color: @color-links;
					}
				}
			}
		}
		.footer-contact {
			margin-bottom: 30px;
			padding-left: 40px;
			p {
				line-height: 26px;
			}
		}
		.footer-newsletter {
			margin-bottom: 30px;
			input[type="email"] {
				border: 0;
				padding: 6px 8px;
				width: 65%;
			}
			input[type="submit"] {
				background: @color-links;
				border: 0;
				width: 35%;
				padding: 6px 0;
				text-align: center;
				color: #FFF;
				transition: 0.3s;
				cursor: pointer;
			}
			input[type="submit"]:hover {
				background: @color-links;
			}
		}
	}
	.copyright {
		text-align: center;
		padding-top: 30px;
	}
	.credits {
		text-align: center;
		font-size: 13px;
		color: #DDD;
	}
}
/* Fim Footer */

/* Responsive Media Queries */
@media (min-width: 768px) {
	#contact .contact-address,
	#contact .contact-phone,
	#contact .contact-wphone,
	#contact .contact-email,
	#contact .contact-facebook,
	#contact .contact-instagram {
		padding: 20px 0px;
	}
	#contact .contact-phone {
		border-left: 1px solid #ddd;
		border-right: 1px solid #ddd;
	}
	// #contact .contact-wphone {
	// 	border-right: 1px solid #ddd;
	// }
	#footer .footer-top .footer-contact {
		margin-bottom: 30px;
	}
}
@media (min-width: 1024px) {
	#header #logo {
		padding-left: 60px;
	}
	#intro p {
		width: 60%;
	}
	#intro .carousel-control-prev, #intro .carousel-control-next {
		width: 5%;
	}
	#nav-menu-container {
		padding-right: 60px;
	}
}
@media (max-width: 768px) {
	.back-to-top {
		bottom: 15px;
	}
	#header #logo h1 {
		font-size: 28px;
	}
	#header #logo img {
		max-height: 40px;
	}
	#intro h2 {
		font-size: 28px;
	}
	#nav-menu-container {
		display: none;
	}
	#mobile-nav-toggle {
		display: inline;
	}
}
@media (max-height: 610px) {
	#intro .carousel-item {
		height: 120vh;
	}
}
@media (max-height: 495px) {
	#intro .carousel-item {
		height: 155vh;
	}
}
@media (max-height: 400px) {
	#intro .carousel-item {
		height: 190vh;
	}
}
@media (max-height: 310px) {
	#intro .carousel-item {
		height: 220vh;
	}
}
@media (max-height: 270px) {
	#intro .carousel-item {
		height: 235vh;
	}
}
@media (max-height: 250px) {
	#intro .carousel-item {
		height: 280vh;
	}
}
@media (max-height: 250px) {
	#intro .carousel-item {
		height: 335vh;
	}
}

#body-planos {
	background: linear-gradient(to right, #FFF, #CCC);
}

.pricing .card {
	border: none;
	border-radius: 1rem;
	transition: all 0.2s;
	box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
	/* background: #0F0D1D; */
  /* color: #FFF; */
}

.pricing hr {
	margin: 1.5rem 0;
}

.pricing .card-title {
	margin: 0.5rem 0;
	font-weight: bold;
	background: #CCC;
	border-top-left-radius: 1rem;
	margin-left: -20px;
	margin-right: -20px;
	margin-top: -20px;
	padding: 25px;
	border-top-right-radius: 1rem;
}

.pricing .card-price {
	font-size: 3rem;
	margin: 0;
}

.pricing .card-price .period {
	font-size: 0.8rem;
}

.pricing ul li {
	margin-bottom: 1rem;
}

.pricing .btn {
	font-size: 80%;
	border-radius: 5rem;
	letter-spacing: .1rem;
	font-weight: bold;
	padding: 1rem;
	transition: all 0.2s;
}

.tp-1 {
	background: #057543 !important;
	color: #FFF !important;
}

.tp-2 {
	background: #053a89 !important;
	color: #FFF !important;
}


.tp-3 {
	background: #4d0ba0 !important;
	color: #FFF !important;
}

.tp-1-color {
	color: #057543 !important;
}


.tp-2-color {
	color: #053a89 !important;
}

.tp-3-color {
	color: #4d0ba0 !important;
}

.tp-1-border {
	border-top: 1px dashed  #057543 !important;
}

.tp-2-border {
	border-top: 1px dashed  #053a89 !important;
}

.tp-3-border {
	border-top: 1px dashed  #4d0ba0 !important;
}

.mais-vendido {
	font-size: 8px;
    background: #FFF !important;
    color: #102c68;
    padding: 8px;
    border-radius: 10px;
    position: absolute;
    margin-left: 27%;
    margin-top: -24px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.blink_me {
  animation: blinker 2.5s linear infinite;
}

@keyframes blinker {
  60% { opacity: 0; }
}

/* Hover Effects on Card */

@media (min-width: 992px) {
	.pricing .card:hover {
		margin-top: -.55rem;
		margin-bottom: .55rem;
		box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
	}
	.pricing .card:hover .btn {
		opacity: 1;
	}
}

.video-container {
    overflow: hidden;
    position: relative;
    width:100%;
    margin-bottom: 25px;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.p-video {
		text-align: center;
		margin-top: 45px;
}

.bloco-video-sm {
	display: initial;
}

.bloco-video-xs {
		display: none;
}

@media (max-width: 991px) {
	.p-video {
		margin-top: 15px;
		margin-bottom: 35px;
	}
	.bloco-video-sm {
		display: none;
	}
	.bloco-video-xs {
		display: initial;
	}
}

#title-comunicado {
	margin-left: 20px;
	font-weight: bold;
}

@media (max-width: 478px) {
	#title-comunicado {
		font-size: 20px;
	}
}

@media (max-width: 420px) {
	#title-comunicado {
		font-size: 17px;
		margin-left: 5px;
	}
}

@media (max-width: 356px) {
	#title-comunicado {
		font-size: 15px;
		margin-left: 0px;
	}
}

#bloco-emissao ul li {
  margin-bottom: 19.5px;
}

.nav-pills-center {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.preco-velho {
    font-size: 18px;
    color: #B7192B;
}